@import '@/scss/abstract/variables';

.navbar {
  z-index: 1;
  color: $dark-gray-text;
  height: $navbar-height;
  background: getColor(light-gray-accent-0);
  box-shadow: $shadow-navbar;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100vw;
    z-index: 3;

    &__left {
      display: flex; // Flex inside left container
      align-items: center;
      justify-content: space-evenly;

      .hamburger-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 40px;
        height: 40px;

        svg {
          fill: getColor(gray-6);
          width: 24px;
          height: 24px;

          &:hover,
          &:active {
            fill: $primary;
            color: $primary;
          }
        }
      }

      .navbar-logo {
        width: 40px;
        margin-left: 20px;
      }
    }

    &__center {
      // Nothing
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .user-profile {
        cursor: pointer;
        height: 40px;
        width: 40px;

        &:hover,
        &:active {
          fill: $primary;
          color: $primary;
        }
      }

      .button-user-name {
        height: 40px;
        min-width: 40px;
        background-color: white;
        border: 0rem;
      }
    }

    svg {
      fill: getColor(gray-7);
      height: 13px;
      width: 13px;
      display: inline;
    }

    .round-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;

      &:hover {
        background-color: getColor(gray-1);
      }
    }

    .user-name-navbar {
      padding-right: 10px;
      color: getColor(gray-7);
    }
  }
}