@import '@/scss/abstract/variables.scss';

$pagination-item-height: 30px;
$pagination-item-weight: 30px;

.pagination {
  &__layout {
    color: getColor(gray-6);
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.item__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $pagination-item-height;
    height: $pagination-item-weight;
    border: 1px solid getColor(gray-2);
    cursor: pointer;

    &:hover {
      background-color: $primary;
      color: $white;
      border: 0;
    }

    &:not(:last-child) {
      border-right: 0;
    }
  }
}


.item__active {
  background-color: $primary;
  color: $white; 
  border: 0;
}

.skip__page {
  justify-content: center;
  align-items: center;
  width: $pagination-item-height;
  height: $pagination-item-weight;
  border: 1px solid getColor(gray-2);
  display: flex;

  svg {
    margin-top: 1px;
    width: 12px;
    height: 12px;
  }

  &:not([disabled]):hover {
    background-color: $primary;

    svg {
      fill: $white;
    }
  }

  &__disable {
    svg {
      fill: getColor(gray-4);
    }
  }
}

.arrow {
  &__btn {
    border: 1px solid getColor(gray-2);
    width: $pagination-item-height;
    height: $pagination-item-weight;

    &:not([disabled]):hover {
      background-color: $primary;

      svg {
        fill: $white;
      }
    }

    &__lastpage {
      @extend .skip__page;
      border-left: 0;
      border-radius: 0px 5px 5px 0px;
    }

    &__firstpage {
      @extend .skip__page;
      border-right: 0;
      border-radius: 5px 0px 0px 5px;
    }

    svg {
      fill: $dark-gray-icon;
    }

    &__disable {
      svg {
        fill: getColor(gray-4);
      }
    }
  }
}
