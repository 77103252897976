@use "@/scss/animation.scss";
@import "@/scss/abstract/variables.scss";

$nav-item-height: 3.45rem;

.sidebar {
  z-index: 0;
  color: $sidebar-content;
  fill: $sidebar-content;
  background: getColor(light-gray-accent-0);
  border-right: 1px solid getColor(gray-2);
  max-width: $sidebar-width;
  max-height: calc(100vh - $navbar-height);
  overflow-y: auto;
  overflow-x: hidden;
  transition: ease 0.5s;

  // Sidebar collapsed style & animation
  &.collapsed {
    max-width: 0px;
    overflow: hidden;
    transition: ease 0.4s;

    // Hide selected sidebar
    .side__group,
    .nav-item {
      &.selected {
        &::after {
          width: 0px;
        }
      }
    }
  }

  // Put center and bottom apart
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  .side__group {
    &_item {
      margin-left: 1.5rem;
      color: $primary;
    }

    &_arrow {
      cursor: pointer;
      margin-right: 0.8rem;
      color: $sidebar-content;
    }
  }

  .center {
    .nav {
      margin-top: 4px;
    }
  }

  .bottom {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: getColor(gray-3);
    font-size: 14px;
  }

  .side__group,
  .nav-item {
    white-space: nowrap;
    height: $nav-item-height;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;

    .sidebar-content {
      color: $sidebar-content; // Specifically overrides Bootstrap styles
      fill: $sidebar-content; // Specifically overrides Bootstrap styles
    }

    .nav-link {
      width: 100%;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 22px;
      font-size: 1rem;
    }

    &:hover {
      &:not(.selected)::after {
        background-color: getColor(gray-1);
        width: calc($sidebar-width - 16px);
        left: 8px;
        right: 8px;
      }

      .sidebar-content {
        &:hover::before {
          background-color: red;
        }

        transition: ease 0.5s;
      }

      a > svg {
        animation: bounce-in ease 0.7s alternate;
      }

      svg {
        animation: swing ease 1.5s infinite;
      }
    }

    &::after {
      content: "";
      background-color: $light-background-accent;
      border-radius: 10px;
      width: 0;
      height: $nav-item-height;
      position: absolute;
      transition: ease 0.4s;
      z-index: -1;
    }

    &.selected {
      &::after {
        width: calc($sidebar-width - 16px);
        left: 8px;
        right: 8px;
      }

      .sidebar-content,
      .side__group_item {
        color: $primary;
        fill: $primary;
      }
    }
  }
}
