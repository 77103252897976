// Contains all Animations throughout the project.

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounce-in {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.08);
  }

  80% { transform: scale(.97); }

  100% { transform: scale(1); }
}

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: translateY(0px);
  }

  10% {
    transform: translateY(2px);
  }

  40% {
    transform: translateY(-2px);
  }

  60% {
    transform: translateY(2px);
  }

  80% {
    transform: translateY(-2px);
  }
}

@keyframes button-pressed {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
