@import '@/scss/abstract/variables.scss';

.table-row-action {
  &:hover {
    cursor: pointer;
  }
}

.more-menu-a {
  border: 1px solid getColor(gray-2);
  padding: 4px 8px;
  border-radius: 3.2px;
  color: $dark-gray-text;
  transition: all 0.2s ease;

  &:hover {
    padding: 6px 10px;
    border: 1px solid getColor(gray-4);
    color: $dark-gray-text;
  }
}

.more-menu-a--icon {
  font-size: 18px;
}

.more-menu-item {
  color: $dark-gray-text;

  &:hover {
    color: $dark-gray-text;
  }

  svg {
    margin-right: 8px;
  }
}

