@import '@/scss/abstract/variables';

// Custom Bootstrap Maintheme before the lib is loaded.
$primary: #1EC171; //primary
$secondary: #606e76; //gray-6
$warning: #ffc457; //yellow-4
$danger: #ff2974; //pink-5
$success: #1fd65f; //light-green-7
$info: #6434f4; //violet-5

// Theme Button Shadow Effects
$button-shadow-primary: 0px 10px 25px rgba($primary, 0.5);
$button-shadow-secondary: 0px 10px 25px rgba($secondary, 0.5);
$button-shadow-warning: 0px 10px 25px rgba($warning, 0.5);
$button-shadow-danger: 0px 10px 25px rgba($danger, 0.5);
$button-shadow-success: 0px 10px 25px rgba($success, 0.5);
$button-shadow-info: 0px 10px 25px rgba($info, 0.5);

// Theme Input box
$input-border-color: getColor(gray-2);
$input-border-radius: 4px;
$form-label-color: getColor(gray-7);
$input-placeholder-color: getColor(gray-4);

// Override bootstrap default font color
$body-color: #4c575d;

//breadcrumb
$breadcrumb-divider: quote('/');
$breadcrumb-divider-color: getColor(gray-3);
$breadcrumb-active-color: getColor(gray-7);
$breadcrumb-font-size: 20px;
$breadcrumb-margin-bottom: 60px;

// Import Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';

/* 
    ==============================================================================================
                                        APOLLO 21 Dev, Listen up!
      Write your custom CSS styles which will override bootstrap default style only in this file!

                                                THANK YOU
    ==============================================================================================
*/

body {
  .modal-header {
    border-bottom: none;
    padding: 1.5rem 1.5rem 0.5rem 2rem;
    color: getColor(gray-7);

    .modal-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
  }

  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
  }

  .modal-footer {
    border-top: none;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
  }

  .modal-content {
    top: 10vh;
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(#000, 0.15);
    border-radius: 4px;
  }
}

/*
========== Buttons ==========
*/
body .btn {
  min-width: 100px;
  border-radius: 4px;
}

// Overrides BS theme color
.btn {

  // On Primary Button Pressed
  &-primary,
  &-outline-primary {
    &:active {
      animation: button-pressed 0.05s 1 forwards;
    }
  }

  &-primary,
  &-success,
  &-danger {
    color: white;

    &:hover,
    &:focus,
    &:active {
      color: white;
    }
  }

  &-outline-primary,
  &-outline-success,
  &-outline-danger {
    &:hover {
      color: white;
    }
  }

  // Modify Theme Button Shadow Effects
  &:hover,
  &:active,
  &:focus {
    &.btn-primary,
    &.btn-secondary,
    &.btn-warning,
    &.btn-danger,
    &.btn-success,
    &.btn-info {
      filter: brightness(110%);
      transition: ease 0.5s;
    }
  }
}

/*
========== breadcrumb ==========
*/

.breadcrumb {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;

  .breadcrumb-item {
    a {
      color: getColor(gray-3);
      text-decoration: none;

      &:hover {
        color: $primary;
      }
    }
  }
}

select {
  cursor: pointer;
}