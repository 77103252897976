@import '@/scss/abstract/variables.scss';

.react-bootstrap-table {

  .table > :not(caption) > * > * {
    border-bottom-width: 0px
  }

  &.table-responsive {
     overflow-x: unset;
  }

  .table {
    color: $dark-gray-text;

    & > :not(:first-child) {
      border-top: none;
    }

    tbody {
      td:first-child {
        box-shadow: 1px -1px 0px 0px getColor(gray-1) inset; // customize our border because library is not cover enough
        border-width: 0 0 0 0;
      }

      td:not(:first-child) {
        border-width: 0 0 0 0;
        box-shadow: 0px -1px 0px 0px getColor(gray-1) inset; // customize our border because library is not cover enough
      }

      td:last-child {
        box-shadow: 0px -1px 0px 0px getColor(gray-1) inset; // customize our border because library is not cover enough
        border-width: 0 1px 0 0;
      }
    } 

    th {
      padding: 15px 0;
      background-color: $primary;
      color: white;
      font-weight: 400;
      border-bottom: none;

      &:first-child {
        border-top-left-radius: 4px;
        padding-left: 21px;
      }
      

      &:last-child {
        border-top-right-radius: 4px;
        padding-right: 21px;
      }
    }

    td {
      padding: 15px 0;
      overflow-wrap: break-word;

      &:first-child {
        padding-left: 21px;
      }

      &:last-child {
        padding-right: 21px;
      }
    }
  }
}

.pagination-content {
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
}
