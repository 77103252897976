@import '@/scss/abstract/variables.scss';

.search-group {

  .search-text-box {
    height: 2.7rem;
    padding-right: 2.5rem;
    width: 100%;
  }

  .icon-search-content {
    position: absolute;
    top: 0.5rem;
    right: 0.8rem;
  }
}
