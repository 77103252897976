//@import "./temp.scss"; //need to remove later, Just for initiate project
@import '@/scss/abstract/_variables';
@import '@/scss/layout/_layout';
@import '@/scss/custom.scss'; // custom from bootstrap
@import '@/scss/font.scss';
@import '@/scss/app.scss';

html {
  font-size: 14px; // This will define the rem multiplier for the entire aplpication;
}

body {
  font-family: 'Kanit', 'Prompt', sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}
