@import '@/scss/abstract/variables';

/* 
    ==============================================================================================
                                        APOLLO 21 Dev, Listen up!
                                Only use app.scss to define global styles

                                                THANK YOU
    ==============================================================================================
*/

// Button size for buttons icon only
.btn.icon-only {
  display: inline-block;
  min-width: 32px;
  height: 32px;
}

// General style for button with icon and text
.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    height: 20px;
    width: 20px;
  }
  .icon ~ span {
    margin-left: 0.5rem;
  }
}
