// ====================================
// Kanit
// ====================================
@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-Bold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: url(~/public/fonts/Kanit-ExtraBold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

// ====================================
// Prompt
// ====================================
@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-Bold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url(~/public/fonts/Prompt-ExtraBold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

.text-h1, h1 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
    
.text-h2, h2 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}
  
.text-h3, h3 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.text-subtitle1 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24.1px;
}
  
.text-subheader1 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}
  
.text-subheader2 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.1px;
}
  
.text-input-text {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
  
.text-body1 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
  
.text-body2 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
  
.text-body3 {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
  
.text-tooltip {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}
  
.text-button-large {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
}
.text-button-medium {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
}
  
.text-button-small {
  font-family: "Gotham Rounded", "Prompt", "Roboto", Verdana, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
}
