@import '@/scss/abstract/_variables.scss';

.management-wrapper {
  margin: 5rem;

  .table {
    margin-top: 1rem;
  }
}

.searchbox-wrapper {
  height: 2.5rem;
  width: 40%;
  margin: 0 0 10px auto;
  display: flex;
  justify-content: space-evenly;
  column-gap: 1rem;

  &__add {
    width: 30%;
  }
}

.active {
  color: white;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 60px;
}

.active-on {
  background-color: getColor(light-green-7);
}

.active-off {
  background-color: getColor(pink-5);
}
