@import '@/scss/abstract/variables';

.container-loading {
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(3px);
  position: absolute;
  top: 0;

  .background {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: getColor(gray-6);
    opacity: 0.3;
  }

  .container-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
